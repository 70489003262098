import React, { Dispatch, FC, SetStateAction, useCallback, useState } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import classes from './styles.module.css';

interface FilterProp {
    t: (key: string) => string;
    setStartPriceFilter: Dispatch<SetStateAction<any>>;
    setEndPriceFilter: Dispatch<SetStateAction<any>>;
    setHaveDiscountFilter: Dispatch<SetStateAction<any>>;
    setOrderDir: Dispatch<SetStateAction<any>>;
    setOrderBy: Dispatch<SetStateAction<any>>;
}

const Filter: FC<FilterProp> = ({ t, setStartPriceFilter,
    setEndPriceFilter, setHaveDiscountFilter,
    setOrderDir, setOrderBy

}) => {
    const handleStartChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        setStartPriceFilter(value);
    }, [])

    const handleEndChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        setEndPriceFilter(value);
    }, []);

    const haveDiscountChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setHaveDiscountFilter(event.target.checked);
    }, []);

    const handleOrderByChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setOrderBy(event.target.value);
    };
    const handleOrderDirChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setOrderDir(event.target.value);
    };

    return (
        <section className={classes.filter}>

            <div className="row">

                <div className={`col-6 col-md-12 mt-md-4 pt-md-2 ${classes.price__filter}`}>
                    <Form.Label htmlFor="price">{t("filter.price")}</Form.Label>
                    <InputGroup className={classes.input__group}>

                        <Form.Control
                            placeholder={t('filter.start')}
                            aria-label={t('filter.start')}
                            type="number"
                            min={0}
                            onChange={handleStartChange}
                        />
                        <Form.Control
                            placeholder={t('filter.end')}
                            aria-label={t('filter.end')}
                            type="number"

                            min={0}
                            onChange={handleEndChange}
                        />
                    </InputGroup>
                </div>

                <div className={` col-6 col-md-12 pt-md-2 ${classes.sort__filter}`}>
                    <Form.Label htmlFor="sort">{t("filter.Sort")}</Form.Label>
                    <InputGroup className={classes.input__group}>

                        <Form.Select aria-label={t('filter.order_by')} name='order_by' onChange={handleOrderByChange}>
                            <option>{t("filter.order_by")}</option>
                            <option value="name">
                                {t("fields.name")}
                            </option>
                            <option value="price">
                                {t("fields.price")}
                            </option>
                            <option value="discounted_price">
                                {t("fields.discount")}
                            </option>
                        </Form.Select>
                        <Form.Select aria-label={t('filter.sort_dir')}
                            name='order_dir' onChange={handleOrderDirChange}>
                            <option value="">{t('ascending')}</option>
                            <option value="-">{t('descending')}</option>
                        </Form.Select>

                    </InputGroup>
                </div>

                <div className={`col-12 mt-md-4 pt-md-2 ${classes.form__check}`}>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox"
                            id="have_discount" name="have_discount"
                            onChange={haveDiscountChange} />
                        <label className="form-check-label" htmlFor="have_discount">{t("filter.have_discount")}</label>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default Filter;
