import { useDispatch, useSelector } from 'react-redux';
import { addToCart, cartSelector, removeFromCart, updateCartItems } from 'src/store/reducers/cart';
import useLocalStorage from './useLocalStorage';
import { CartItemType } from 'src/store/reducers/cart';
import { useCartActionMutation } from 'src/store/api/cart/catsApiSlice';
import { authSelector } from 'src/store/reducers/auth';


const useCart = (): any => {
    const dispatch = useDispatch();
    const auth = useSelector(authSelector);
    const cartSlug = localStorage.getItem('mk_store_cart');

    const [sendCartAction] = useCartActionMutation();
    const { items } = useSelector(cartSelector);


    const checkHandle = (slug: string) => {
        return items.filter((item: CartItemType) => item.slug === slug).length > 0;
    }

    const addHandle = (newItem: CartItemType) => {
        dispatch(addToCart({ item: newItem }));
        sendCartAction({ action: "add", slug: newItem.slug, qty: 1, cart_slug: cartSlug });
    };

    const removeHandle = (slug: string) => {
        dispatch(removeFromCart({ slug }));
        sendCartAction({ action: "remove", slug: slug, cart_slug: cartSlug });
    };

    const updateQtyHandle = (slug: string, qty: number) => {
        const updatedItems = items.map((item) => {
            return { ...item, qty: item.slug === slug ? qty : item.qty }
        })
        dispatch(updateCartItems({ items: updatedItems }));

        sendCartAction({ action: "update", slug: slug, qty, cart_slug: cartSlug });

    };

    const getItemQty = (slug: string) => {
        // Iterate through the items array
        for (let i = 0; i < items.length; i++) {
            // Check if the current item's ID matches the given ID
            if (items[i].slug === slug) {
                // Return the quantity of the item
                return items[i].qty === undefined ? 1 : items[i].qty;
            }
        }
        // Return null if no item with the given ID is found
        return 1;
    };


    return {
        checkHandle, addHandle,
        removeHandle, cartItems: items,
        getItemQty, updateQtyHandle,
        items
    }
};

export default useCart;