import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';


import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css'
import './setup/i18n';

import { default as routes } from 'src/routes/Routes';


import { authSelector, logOut, setCredentials } from './store/reducers/auth';
import { useRefreshMutation } from './store/api/auth/authApiSlice';
import useCartSync from './hooks/useCartSync';
import { clearStorage } from './utils/auth';
import useError from './hooks/useError';
import { LoadingPage } from './components';
import { isRTL } from './utils/global';


const App = () => {
  const auth = useSelector(authSelector);
  const { cartSync } = useCartSync();
  const [refresh, { isLoading, isError, error }] = useRefreshMutation();
  const dispatch = useDispatch();
  const { showError } = useError();


  // Side Effects
  useEffect(() => {
    if (!auth.access) {
      const reAuthHandle = async () => {
        const refreshToken = localStorage.getItem('mk_store_tk');

        if (refreshToken) {
          const res = await refresh(refreshToken);
          if ('data' in res) {
            const { access } = res.data;
            const userData = {
              access: access,
              refresh: refreshToken
            }
            dispatch(setCredentials(userData));
            cartSync();
          }
        } else {
          clearStorage();
        }
      }
      reAuthHandle();
    }
    cartSync();
  }, []);

  useEffect(() => {
    if (isError) {
      if ((error as any).status === 500 || (error as any).status === "FETCH_ERROR") {
        showError(error);
      }
      if ((error as any).status === 401 || (error as any).status === 403) {
        dispatch(logOut());
        clearStorage();
      }
    }

  }, [isError]);

  if (isLoading) {
    return <LoadingPage />
  }


  return (
    <Router>
      <ToastContainer className={isRTL() ? "rtl_toast" : ""} stacked
        position={isRTL() ? 'top-right' : 'top-left'} />
      <Routes>
        {routes.map((route, index) => (
          <Route key={`layout__${index}`} path={route.path} element={<route.element />}>
            {route.routes.map((nestedRoute, nestedIndex) => (
              <Route
                key={`route__${nestedIndex}`}
                path={nestedRoute.path}
                element={<nestedRoute.element />}
              />
            ))}
          </Route>
        ))}
      </Routes>
    </Router>);
}



export default App;
