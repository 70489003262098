import { useWishlistActionMutation } from 'src/store/api/accounts/accountsApiSlice';
import { useError } from 'src/hooks';



const useWishlist = () => {
    const [wishlistToggle, { error }] = useWishlistActionMutation();
    const { showError } = useError();

    const actionHandle = async (action: string, productID: number) => {
        try {
            const res = await wishlistToggle({ product_id: productID, action: action });
            if ('error' in res) {
                const formError = res.error;
                showError(error);
            }
        }
        catch (err) {

        }
    }

    const wishlistAdding = (productID: number) => {
        actionHandle('add', productID);
    }
    const wishlistRemoving = async (productID: number) => {
        actionHandle('remove', productID);

    }
    return { wishlistAdding, wishlistRemoving };
};

export default useWishlist;
