import apiSlice from "../apiSlice";


const coreApiSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        home: build.query({
            query: () => (`/core/`)
        })
    })
})


export const { useHomeQuery } = coreApiSlice;