import { FC } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import classes from "./styles.module.css";
import { SPINNER_COLOR } from 'src/constants/colors';

interface LoadingPageProp {
    styles?: { [key: string]: string }
    extraClasses?: string
}

const LoadingPage: FC<LoadingPageProp> = ({ styles, extraClasses }) => {
    return (
        <div className={classes.spinner__con}>
            <Spinner className={`${classes.spinner} ${extraClasses}`} animation="border" role="status" style={{ color: SPINNER_COLOR, ...styles }}>
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    )
}

export default LoadingPage