import apiSlice from "../apiSlice";


interface LoginPayload {
    username: string;
    password: string;
    recaptcha_token: string;
}

const authApiSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        login: build.mutation({
            query: (credentials: LoginPayload) => {
                return {
                    url: '/auth/login/',
                    method: "POST",
                    body: credentials
                }
            }
        }),
        refresh: build.mutation({
            query: (refresh: string) => ({
                url: "/auth/refresh/",
                method: "POST",
                body: { refresh: refresh }

            })
        })
    })
})


export const { useLoginMutation, useRefreshMutation } = authApiSlice;