import { FC, memo, useEffect } from "react";
import { Link } from "react-router-dom";
import { HiViewGrid, HiChevronLeft, HiChevronRight } from "react-icons/hi";
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from "react-i18next";

import classes from "./styles.module.css";
import { useCatsQuery } from "src/store/api/cats/catsApiSlice";
import { PRIMARY } from "src/constants/colors";
import { ElementLoading, EmptyElement } from "src/components";
import { CategoryType } from "./types";
import { isRTL } from "src/utils/global";
import { useError, useUpdateEffect } from "src/hooks";
import noProductImg from 'src/assets/images/no_products.svg';


interface CatsMenuProp {
    openMenu: boolean;
    setOpenMenu: any;
}


const CatsMenu = ({ openMenu, setOpenMenu }: CatsMenuProp) => {
    const { t, i18n } = useTranslation(['common']);
    const { data, isLoading, isSuccess, isFetching, refetch, isError, error } = useCatsQuery(undefined, { refetchOnMountOrArgChange: true, refetchOnReconnect: true });
    const closeMenuHandle = () => { setOpenMenu(false) };
    const { showError } = useError();

    useEffect(() => {
        if (isError) {
            showError(error);
        }
    }, [isError]);

    useUpdateEffect(refetch, [i18n.language]);

    return (
        <section className={`${classes.cats__con} ${openMenu ? classes.cats__con__open : ''} ${isRTL() && classes.rtl} `}>
            <div className={`${classes.cats__inline__con} ${openMenu ? classes.cats__inline__open : ''}`}>
                <header className={classes.header}>
                    <div className={classes.title__con}>
                        <HiViewGrid size={28} color={PRIMARY} />
                        <h4>
                            {t("nav.categories.title")}
                        </h4>
                    </div>
                    <div className={classes.close__con} onClick={closeMenuHandle}>
                        {isRTL() ? <HiChevronRight size={28} /> :
                            <HiChevronLeft size={28} />
                        }
                    </div>
                </header>
                <div className={classes.cats}>
                    {
                        isSuccess && !isLoading && !isFetching ?
                            data.length > 0 ? <Accordion defaultActiveKey={`${data.length > 0 ? data[0].id : null}`}>
                                {data.map((cat: CategoryType) => (
                                    <Category key={`cat__${cat.id}`} cat={cat} lang={i18n.language} />
                                ))}
                            </Accordion> :
                                <EmptyElement img={noProductImg}
                                    title={t("null_items")} />
                            :
                            <ElementLoading />
                    }
                </div>
            </div>
            <div className={`${classes.overlay} ${openMenu ? classes.overlay__open : ''}`}
                onClick={closeMenuHandle}>
            </div>

        </section>
    )
}

export default memo(CatsMenu);


interface CategoryProps {
    cat: CategoryType,
    lang: string
}

const Category: FC<CategoryProps> = ({ cat, lang }) => {
    return cat.children.length > 0 ? (
        <Accordion.Item eventKey={`${cat.id}`} className={classes.accordion__item}>
            <Accordion.Header>
                {lang.includes('ar') ? cat.name_ar : cat.name_en}
            </Accordion.Header>
            <Accordion.Body className={classes.accordion__body}>
                <ul className={classes.cat__links}>
                    {cat.children.map((sub_cat) => (
                        <li key={`sub_cat__${sub_cat.id}`}>
                            <Link to={`/categories/${sub_cat.slug}/products/`} className={classes.cat__menu__link}>
                                {lang.includes('ar') ? sub_cat.name_ar : sub_cat.name_en}
                            </Link>
                        </li>))}
                </ul>
            </Accordion.Body>
        </Accordion.Item>
    ) : (
        <div className={classes.cat__link__con}>
            <Link to={`/categories/${cat.slug}/products/`} className={classes.cat__link}>
                {lang.includes('ar') ? cat.name_ar : cat.name_en}
            </Link>
        </div>
    );
}

