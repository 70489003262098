import apiSlice from "../apiSlice";


const cartApiSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        cartSync: build.mutation({
            query: (cartSlug) => {
                return {
                    url: "/carts/sync/",
                    method: "POST",
                    body: {
                        cart_slug: cartSlug
                    }
                }
            }
        }),
        cartAction: build.mutation({
            query: (action: {
                action: string;
                qty?: number,
                slug: string,
                cart_slug: string | null
            }) => {
                return {
                    url: "/carts/action/",
                    method: "POST",
                    body: action
                }
            }
        })
    })
})


export const { useCartSyncMutation, useCartActionMutation } = cartApiSlice;