import apiSlice from "../apiSlice";


const catsApiSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        cats: build.query({
            query: () => (`/categories/`)
        }),
        catProducts: build.query({
            query: ({ slug, params }) => (`/categories/${slug}/products/?${params}`)
        })
    })
})


export const { useCatsQuery, useCatProductsQuery } = catsApiSlice;