import { FaLock } from "react-icons/fa6";
import classes from './styles.module.css';
import { useTranslation } from "react-i18next";


function PermsDenied() {
    const { t } = useTranslation(['common']);
    return (
        <section className={classes.perms__denied}>
            <div className={classes.card}>
                <div className={classes.icon__con}>
                    <FaLock size={32} />
                </div>
                <h4 className={classes.title}>
                    {t("perms_denied.title")}
                </h4>
                <p className={classes.content}>
                    {t("perms_denied.msg")}
                </p>
                <a href="/" className={`btn__primary ${classes.btn}`}>
                    {t("perms_denied.btn")}
                </a>
            </div>
        </section>
    )
}

export default PermsDenied;