import { lazy } from 'react';

import Main from 'src/layout/main/Main';
import Auth from 'src/layout/auth/Auth';
import Home from "src/pages/home/Home";


const About = lazy(() => (import("src/pages/about/About")));
const PrivacyPolicy = lazy(() => (import("src/pages/privacy_policy/PrivacyPolicy")));
const TermsConditions = lazy(() => (import("src/pages/terms_conditions/TermsConditions")));
const RefundPolicy = lazy(() => (import("src/pages/refund_policy/RefundPolicy")));
const Profile = lazy(() => (import("src/pages/accounts/profile/userProfile/Profile")));
const Wishlist = lazy(() => (import("src/pages/wishlist/Wishlist")));
const Orders = lazy(() => (import("src/pages/orders/Orders")));
const Products = lazy(() => (import("src/pages/products/Products")));
const CategoryProducts = lazy(() => (import("src/pages/cats/catProducts/CatProducts")));
const ProductDetail = lazy(() => (import("src/pages/productDetail/ProductDetail")));
const Login = lazy(() => (import("src/pages/auth/login/Login")));
const Register = lazy(() => (import("src/pages/accounts/register/Register")));
const PasswordRecovery = lazy(() => (import("src/pages/accounts/passwordRecovery/PasswordRecovery")));
const PasswordConfirm = lazy(() => (import("src/pages/accounts/passwordRecovery/confirm/Confirm")));
const PasswordReset = lazy(() => (import("src/pages/accounts/passwordRecovery/reset/Reset")));
const Confirmation = lazy(() => (import("src/pages/accounts/register/confirmation/Confirmation")));
const Cart = lazy(() => (import("src/pages/cart/Cart")));


const routes = [
    {
        path: '/',
        element: Main,
        routes: [
            {
                path: '',
                element: Home
            },
            {
                path: 'about-us',
                element: About
            },
            {
                path: 'privacy-policy',
                element: PrivacyPolicy
            },
            {
                path: 'terms-conditions',
                element: TermsConditions
            },
            {
                path: 'refund-policy',
                element: RefundPolicy
            },
            {
                path: 'products',
                element: Products
            },
            {
                path: 'products/:slug',
                element: ProductDetail
            },
            {
                path: 'cart',
                element: Cart
            },
            {
                path: 'categories/:slug/products',
                element: CategoryProducts
            },
            {
                path: 'profile',
                element: Profile
            },
            {
                path: 'orders',
                element: Orders
            },
            {
                path: 'wishlist',
                element: Wishlist
            }

        ]
    },
    {
        path: '/auth/',
        element: Auth,
        routes: [
            {
                path: 'login',
                element: Login
            },
            {
                path: 'register',
                element: Register
            },
            {
                path: 'confirmation/:username/:publicCode',
                element: Confirmation
            },
            {
                path: "password/recovery",
                element: PasswordRecovery
            },
            {
                path: 'password/confirmation/:publicCode',
                element: PasswordConfirm
            },
            {
                path: 'password/reset',
                element: PasswordReset
            }
        ]
    }
];

export default routes;
