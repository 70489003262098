import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { authSelector } from 'src/store/reducers/auth';

type Callback = () => void;

const useAuthEffect = (callback: Callback,): void => {
    const auth = useSelector(authSelector);
    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            if (auth.access) {
                isFirstRender.current = false;
            }
        } else {
            callback();
        }
    }, [auth.access]);
};

export default useAuthEffect;
