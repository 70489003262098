import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';


import enCommon from './locales/en/common.json';
import arCommon from './locales/ar/common.json';

import enHome from './locales/en/home.json';
import arHome from './locales/ar/home.json';

import enAuth from './locales/en/auth.json';
import arAuth from './locales/ar/auth.json';


import enProducts from './locales/en/products.json';
import arProducts from './locales/ar/products.json';


import enCart from './locales/en/cart.json';
import arCart from './locales/ar/cart.json';

import enAccounts from './locales/en/accounts.json';
import arAccounts from './locales/ar/accounts.json';


i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        supportedLngs: ["en", "ar"],
        resources: {
            en: {
                common: enCommon,
                home: enHome,
                auth: enAuth,
                products: enProducts,
                cart: enCart,
                accounts: enAccounts
            },
            ar: {
                common: arCommon,
                home: arHome,
                auth: arAuth,
                products: arProducts,
                cart: arCart,
                accounts: arAccounts
            }
        },
        ns: ['common', 'home', 'auth',
            'products', 'cart', 'accounts'],
        defaultNS: "common",
        // debug: process.env.NODE_ENV === "development",

    });


export default i18n;