import React, { Dispatch, FC, ReactNode, SetStateAction } from 'react'
import Alert from 'react-bootstrap/Alert';

import classes from './styles.module.css';

interface AlertProp {
    show: boolean,
    setShow?: Dispatch<SetStateAction<boolean>>
    msg?: string | ReactNode,
    variant?: string,
    header?: string,
    children?: ReactNode
}

const CustomAlert: FC<AlertProp> = ({ show, setShow,
    msg, variant,
    header, children }) => {
    return (
        <Alert variant={variant ? variant : "success"} show={show} onClick={setShow ? () => setShow(false) : undefined} className={classes.alert} >
            {header && <Alert.Heading>
                {header}
            </Alert.Heading>}
            {msg}
            {children}
        </Alert>
    )
}

export default CustomAlert;