import { useEffect } from "react";
import { useSelector } from "react-redux";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "./slider/Slider"
import Newset from "./newset/Newset";
import Offers from "./offers/Offers";
import BestSelling from "./best/Best";
import { useHomeQuery } from "src/store/api/core/coreApiSlice";
import { ErrorScreen, LoadingPage } from "src/components";
import { authSelector } from "src/store/reducers/auth";




const Home = () => {
    const auth = useSelector(authSelector);
    const { data, isLoading, isFetching
        , isError, error, refetch } = useHomeQuery(undefined, {
            refetchOnReconnect: true,
            refetchOnMountOrArgChange: true,
        });

    useEffect(() => {
        refetch();
    }, [auth.access])

    if (isError) {
        console.log(error)
        return <ErrorScreen />
    }




    // return isSuccess && !isLoading && !isFetching ? (
    //     <>
    //         <Slider data={data.slider_products} />
    //         <Newset data={data.latest} />
    //         <Offers data={data.offers} />
    //         <BestSelling data={data.best} />
    //     </>

    // ) : <LoadingPage />

    return (
        <>

            <Slider data={data?.slider_products || []} />
            <Newset data={data?.latest || []} />
            <Offers data={data?.offers || []} />
            <BestSelling data={data?.best || []} />
            {(isLoading || isFetching) && <LoadingPage />}
        </>

    )
}

export default Home