import { memo } from 'react'
import classes from "./styles.module.css";


function AnimatedBG() {
    return (
        <ul className={classes.circles}>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
        </ul>
    )
}

export default memo(AnimatedBG);