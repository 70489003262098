import classes from './styles.module.css';
import { useTranslation } from 'react-i18next';
import { FaCircleXmark } from "react-icons/fa6";

import ErrorImg from 'src/assets/images/500-server-error.svg';


const ErrorScreen = () => {
    const { t } = useTranslation();

    const handleRefresh = () => {
        window.location.reload();
    };

    return (
        <div className={classes.wrapper}>
            <div className={classes.card}>
                
                <div className={classes.img__con}>
                    <img src={ErrorImg} />
                </div>

                <div>
                    <p className={classes.content}>
                        {t("errors.screen.content")}
                    </p>
                    <div className={classes.btn__con}>
                        <button onClick={handleRefresh} type='button' className='btn__primary' >
                            {t("errors.screen.btn")}
                        </button>
                    </div>
                </div> 
            </div>
        </div>
    )
}

export default ErrorScreen;