import { Suspense } from 'react';
import { Outlet, Navigate, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { HiChevronRight, HiChevronLeft } from "react-icons/hi";

import image from 'src/assets/images/bg.svg';
import classes from './styles.module.css';
import { authSelector } from 'src/store/reducers/auth';
import { isRTL } from 'src/utils/global';
import { useLocaitonQuery } from 'src/hooks';
import { LangMenu, LoadingPage } from 'src/components';


const AuthLayout = () => {
    const auth = useSelector(authSelector);
    const { query } = useLocaitonQuery();
    const next = query.get('next');
    const redirectPath = next && !next.includes('auth') ? next : '/';


    return auth.access ? <Navigate to={redirectPath} replace={true} /> : (
        <main className={`${classes.auth__layout} ${isRTL() && "rtl"} ${isRTL() && classes.rtl}`} >
            <div className={classes.bg}>
                <div className={classes.lang__con}>
                    <Link to="/" className={`${classes.link}`}>
                        {isRTL() ?
                            <>
                                <HiChevronRight size={22} />
                                الرئيسية
                            </>
                            :
                            <>
                                <HiChevronLeft size={22} /> Home
                            </>
                        }


                    </Link>
                    <LangMenu className={classes.menu_btn} />

                </div>
                <img src={image} alt={"Malek Store"} />
            </div>
            <div className={classes.content} >
                <Suspense fallback={<LoadingPage />}>
                    <Outlet />
                </Suspense>
            </div>

        </main >

    );
}
export default AuthLayout;
