import { useDispatch } from 'react-redux';
import { setLoadingTrue, updateCartItems, setLoadingFalse } from 'src/store/reducers/cart';
import { useCartSyncMutation } from 'src/store/api/cart/catsApiSlice';


const useCartSync = () => {
    const dispatch = useDispatch();
    const cartSlug: string | null = localStorage.getItem('mk_store_cart');
    const [syncCart] = useCartSyncMutation();


    // ==============================
    // Handlers =====================
    // ==============================
    const cartSync = async () => {
        dispatch(setLoadingTrue());

        const res = await syncCart(cartSlug);
        if ('data' in res && res.data) {
            const cartData = res.data.items.map((item: any) => ({
                name: item.product.name,
                slug: item.product.slug,
                img: item.product.image,
                price: item.product.price,
                disc_price: item.product.disc_price,
                qty: item.quantity
            }));
            dispatch(updateCartItems({ items: cartData }));
            localStorage.setItem('mk_store_cart', res.data.cart_slug);
            dispatch(setLoadingFalse());
        }

    }


    return { cartSync }
};

export default useCartSync;