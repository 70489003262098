import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import classes from './styles.module.css';

import { ItemType } from '../types';
import { isRTL, truncateText, truncateWords } from 'src/utils/global';


interface ItemProp {
    item: ItemType,
    defaultItem?: boolean
}

const Item: FC<ItemProp> = ({ item, defaultItem }) => {
    const { t } = useTranslation(['home']);
    const navigate = useNavigate();

    const onClickHandle = () => {
        navigate(`/products/${item.slug}/`);
    }

    return (
        <div className={`${classes.item} ${isRTL() && classes.rtl}`}>
            <div className={classes.start}>
                <div className={classes.content}>
                    <h2>
                        {truncateWords(item.name, 6)}
                    </h2>
                    <p>
                        {truncateText(item.overview, 35, 192)}
                    </p>

                    {!defaultItem && <div className={classes.btn__con}>
                        <button onClick={onClickHandle} className={`btn__primary ${classes.btn}`}>
                            {t("slider.item.btn")}
                        </button>
                    </div>}


                </div>
            </div>
            <div className={classes.end}>
                <div className={classes.img__con}>
                    <img src={item.image} alt={item.name} width={375} height={375} loading='lazy' />
                </div>
            </div>

        </div>
    )
}

export default Item;