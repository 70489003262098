import { FC } from 'react';
import classes from './styles.module.css';


interface TitleProp {
    title: string;
}

const Title: FC<TitleProp> = ({ title }) => {
    return (
        <h2 className={classes.title}>
            {title}
        </h2>
    )
}

export default Title;