import { useTranslation } from "react-i18next";
import Title from "../title/Title";
import classes from "./styles.module.css";
import ProductsSlider from "../newset/productsSlider/ProductsSlider";
import { FC } from "react";
import { ProductType } from "src/pages/products/product/types";

interface BestSellingProp {
    data: ProductType[]
}

const BestSelling: FC<BestSellingProp> = ({ data }) => {
    const { t } = useTranslation(['home']);
    return (
        <section className={classes.best}>
            <div className="container">
                <Title title={t('best.title')} />
                <ProductsSlider data={data} />
            </div>

        </section>
    )
}


export default BestSelling;