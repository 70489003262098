import apiSlice from "../apiSlice";


interface WishlistActionPayload {
    action: string,
    product_id: string | number
}


const accountsApiSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        wishlist: build.query({
            query: () => ("/accounts/wishlist/")
        }),
        wishlistAction: build.mutation({
            query: ({ action, product_id }: WishlistActionPayload) => {
                return {
                    url: "/accounts/wishlist/",
                    method: "POST",
                    body: {
                        action: action,
                        product_id: product_id
                    }
                }
            }
        }),
        register: build.mutation({
            query: (bodyData) => {
                return {
                    url: "/accounts/register/",
                    method: "POST",
                    body: bodyData
                }
            }
        }),
        activate: build.mutation({
            query: (bodyData) => {
                return {
                    url: "/accounts/activation/",
                    method: "POST",
                    body: bodyData
                }
            }
        }),
        resendActivation: build.mutation({
            query: (bodyData) => {
                return {
                    url: "/accounts/resend/activation/",
                    method: "POST",
                    body: bodyData
                }
            }
        }),
        passwordRecovery: build.mutation({
            query: (bodyData) => {
                return {
                    url: "/accounts/password/recovery/",
                    method: "POST",
                    body: bodyData
                }
            }
        }),
        passwordConfirm: build.mutation({
            query: (bodyData) => {
                return {
                    url: "/accounts/password/confirmation/",
                    method: "POST",
                    body: bodyData
                }
            }
        }),
        passwordReset: build.mutation({
            query: (bodyData) => {
                return {
                    url: "/accounts/password/reset/",
                    method: "POST",
                    body: bodyData
                }
            }
        }),
        userDetails: build.query({
            query: () => "/accounts/profile/"
        }),
        updateProfile: build.mutation({
            query: (bodyData) => {
                return {
                    url: "/accounts/profile/",
                    method: "PATCH",
                    body: bodyData
                }
            }
        }),
        deleteAccount: build.mutation({
            query: (args) => {
                return {
                    url: "/accounts/delete/",
                    method: "DELETE"
                }
            }
        })
    })
})


export const { useWishlistActionMutation, useRegisterMutation,
    useActivateMutation, usePasswordRecoveryMutation,
    usePasswordConfirmMutation, usePasswordResetMutation,
    useUserDetailsQuery, useUpdateProfileMutation,
    useDeleteAccountMutation,
    useWishlistQuery,
    useResendActivationMutation } = accountsApiSlice;