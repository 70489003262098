import { useTranslation } from 'react-i18next';
import Dropdown from 'react-bootstrap/Dropdown';

import { FaAngleDown } from "react-icons/fa6";
import classes from "./styles.module.css";


const LANGUAGES: {
    [key: string]: string
} = {
    en: "English",
    ar: "Arabic"
}

interface LangMenuProp {
    className?: string
}

const LangMenu = ({ className }: LangMenuProp) => {
    const { i18n } = useTranslation();

    const changeLanguageHandle = (lang: string) => {
        return i18n.changeLanguage(lang);
    }

    return (
        <Dropdown>
            <Dropdown.Toggle id="dropdown-basic" className={[classes.lang__btn, className].join(' ')}>
                {LANGUAGES[i18n.language]}
                <FaAngleDown />
            </Dropdown.Toggle>

            <Dropdown.Menu className={classes.lang__dropdown}>
                <Dropdown.Item onClick={() => changeLanguageHandle("ar")}>
                    Arabic
                </Dropdown.Item>
                <Dropdown.Item onClick={() => changeLanguageHandle("en")}>
                    English
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default LangMenu