import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import { FC } from 'react';

import classes from './styles.module.css';
import './styles.css';

import Item from "./Item/Item";
import { ItemType } from "./types";
import AnimatedBG from "src/components/animatedBg/AnimatedBG";
import MainImg from "src/assets/images/main_img.png";
import { isRTL } from "src/utils/global";

interface HomeSliderProp {
    data: ItemType[]
}


const defaultItemData = (lang: string) => ({
    id: "dt_i_id",
    slug: "dt_i_slug",
    image: MainImg,
    overview: lang.includes('ar') ? `مرحبًا بكم في متجر مالك، وجهتك للمنتجات عالية الجودة والخدمة الاستثنائية. تسوق بثقة واكتشف عالمًا من الراحة والأناقة في متناول يدك.` :
        `Welcome to Malek Store, your destination for quality products and exceptional service. Shop with confidence and discover a world of convenience and style at your fingertips.`,
    name: lang.includes('ar') ? "مالك ستور" : "Malek Store"

})

const HomeSlider: FC<HomeSliderProp> = ({ data }) => {
    const { i18n } = useTranslation(['home']);

    return (
        <div className={classes.slider__con}>
            <AnimatedBG />
            <div className="container">
                <Slider {...SETTINGS} className={isRTL() ? classes.rtl : ""}>
                    <Item defaultItem={true} key={`slider__item__default`}
                        item={defaultItemData(i18n.language)} />
                    {
                        data.map((item, index) => (
                            <Item key={`slider__item__${index}`} item={item} />
                        ))
                    }
                </Slider>
            </div>
        </div>
    );
}

export default HomeSlider;


const SETTINGS = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
};
