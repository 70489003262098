
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IconType } from 'react-icons';

import classes from "./styles.module.css";

import logo from "src/assets/images/logo.svg";
import {
    FaWhatsapp, FaPhone, FaFacebookF,
    FaInstagram, FaEnvelope,
    FaLocationDot
} from "react-icons/fa6";
import { copyToClipBoard } from 'src/utils/global';
import { toast } from 'react-toastify';
import { LangMenu } from "src/components";




interface Path {
    path?: string;
    title: string;
    icon?: IconType;
    callback?: (item: string | undefined) => void
}

interface DataItem {
    title: string;
    paths: Path[];
}



function Footer() {
    const { t, i18n } = useTranslation(['common']);


    const linkHandler = (url: string) => {
        window.open(url, '_blank');
    };


    const DATA: DataItem[] = [
        {
            title: "footer.social.title",
            paths: [
                {
                    title: "footer.social.facebook", icon: FaFacebookF,
                    callback: () => {
                        console.log("Phone Number")
                    }
                },
                {
                    title: "footer.social.instagram", icon: FaInstagram,
                    callback: () => {
                        console.log("Phone Number")
                    }
                }

            ]
        },
        {
            title: "footer.contact.title",
            paths: [
                {
                    title: "footer.contact.phone_number", icon: FaPhone,
                    callback: async () => {
                        const copied = await copyToClipBoard('+218911056777');
                        if (copied) {
                            const text = i18n.language === 'ar' ? 'تم النسخ 👍' : 'Copied 👍'
                            toast(text, {
                                style: { width: 120, top: -70 },
                                position: i18n.language === 'ar' ? "top-right" : "bottom-left",
                                autoClose: 1000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: false,
                                draggable: false,
                                progress: undefined,
                                theme: "light",
                            });
                        } else {
                            console.log("Error");
                        }
                    }
                },
                {
                    title: "footer.contact.whatsapp", icon: FaWhatsapp, callback: () => linkHandler("https://wa.me/+218911056777")
                },
                {
                    title: "info@malek-store.ly", icon: FaEnvelope,
                    callback: () => linkHandler("mailto:info@malek-store.ly")
                },
                {
                    title: "footer.contact.location", icon: FaLocationDot,
                    callback: () => linkHandler("https://www.google.com/maps?q=32.3769416809082,15.09591293334961&z=17&hl=ar")
                }
            ]
        },
        {
            title: "footer.policies.title",
            paths: [
                { path: "/privacy-policy", title: "footer.policies.privacy_policy" },
                { path: "/terms-conditions", title: "footer.policies.terms_conditions" },
                { path: "/refund-policy", title: "footer.policies.refund_policy" }
            ]

        }
    ];


    return (
        <footer className={classes.footer}>
            <div className="container">
                <div className={classes.content}>
                    <div className="row">
                        <div className="col-lg-5 col-md-4 col-12">
                            <div className={classes.start}>
                                <div className={classes.img__con}>
                                    <img src={logo} alt="Malek Store" />
                                </div>
                                <div>
                                    <LangMenu />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-8 col-12">

                            <div className={classes.end}>
                                {DATA.map((item, index) => {
                                    return <div className={classes.links} key={`f__item__${index}`}>
                                        <h5 className={classes.title}>
                                            {t(item.title)}
                                        </h5>
                                        <ul>
                                            {item.paths.map((link, index) => (
                                                <li key={`f__links__${index}`}>
                                                    {link.path ?
                                                        <Link to={link.path} className={classes.link}>
                                                            {link.icon &&
                                                                <div className={classes.icon}>
                                                                    <link.icon size={18} />
                                                                </div>}

                                                            <div>
                                                                {t(link.title)}
                                                            </div>
                                                        </Link>
                                                        : link.callback &&
                                                        <div className={classes.link} onClick={() => link.callback && link.callback('091 113 8657')}>
                                                            {link.icon &&
                                                                <div className={classes.icon}>
                                                                    <link.icon size={18} />
                                                                </div>}

                                                            <div>
                                                                {t(link.title)}
                                                            </div>
                                                        </div>
                                                    }

                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>


                </div>
                <div className={classes.copyright__con}>
                    <div className={classes.copyright}>
                        {t('footer.copyright')}
                    </div>
                    <div className={classes.madeBy}>
                        {i18n.language.includes('ar') ?
                            <>
                                هذا الموقع صنع بواسطة
                                <a href='https://www.facebook.com/libyadigitalwizard'>
                                    LDW Team
                                </a> &
                                <a href='https://www.facebook.com/silatech.ly'>
                                    Sila Tech
                                </a>
                            </>
                            : (<>
                                This site was made by
                                <a href='https://www.facebook.com/libyadigitalwizard'>
                                    LDW Team
                                </a> &
                                <a href='https://www.facebook.com/silatech.ly'>
                                    Sila Technology
                                </a>
                            </>)
                        }
                    </div>
                </div>
            </div>

        </footer>
    )
}

export default Footer;








