import {
    memo, FC, useState,
    useEffect, Dispatch, SetStateAction,
    useRef
} from 'react';
import { Element } from 'react-scroll';

import classes from './styles.module.css';
import { HiOutlineSearch } from "react-icons/hi";


interface SearchProp {
    setSearch: Dispatch<SetStateAction<string>>;
    focus?: boolean
}

const Search: FC<SearchProp> = ({ setSearch, focus }) => {
    const [searchTerm, setSearchTerm] = useState<string>('');
    const inputRef = useRef<HTMLInputElement>(null);


    useEffect(() => {
        const handler = setTimeout(() => {
            setSearch(searchTerm);
        }, 500); // 500ms debounce time

        // Cleanup timeout if the component unmounts or the searchTerm changes
        return () => {
            clearTimeout(handler);
        };
    }, [searchTerm, setSearch]);

    const searchOnChangeHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    useEffect(() => {
        if (focus && inputRef.current) {
            inputRef.current.focus();
        }
    }, [focus]);


    return (
        <Element name="searchBar">
            <div className={classes.search}>
                <div className={`input-group ${classes.input__group}`}>
                    <input
                        type="text"
                        className="form-control search-input"
                        placeholder="Search"
                        aria-label="Search"
                        aria-describedby="button-addon2"
                        onChange={searchOnChangeHandle}
                        ref={inputRef}

                    />
                    <button className="btn" type="button" id="button-addon2">
                        <HiOutlineSearch size={22} />
                    </button>
                </div>
            </div>
        </Element>
    );
}

export default memo(Search);
