import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import DarkLogo from "src/assets/images/dark_logo.png";


import {
    HiViewGrid, HiMenu, HiOutlineSearch,
    HiOutlineShoppingCart, HiOutlineUserCircle,
    HiOutlineLogout, HiOutlineUser,
    HiOutlineHome, HiOutlineShoppingBag, HiOutlineHeart,
    HiOutlineQuestionMarkCircle, HiOutlineLogin

} from "react-icons/hi";

import { authSelector, logOut } from 'src/store/reducers/auth';
import classes from './styles.module.css';
import { Link, NavLink } from 'react-router-dom';
import logo from 'src/assets/images/logo.svg';
import CatsMenu from '../cats/CatsMenu';
import { cartCountSelector, cartLoadingSelector, cartReset } from 'src/store/reducers/cart';
import { clearAllStorage } from 'src/utils/auth';
import { isRTL } from 'src/utils/global';


const ICON_SIZE = 22


export default () => {
    const { t, i18n } = useTranslation(['common']);
    const dispatch = useDispatch();

    const [openMenu, setOpenMenu] = useState(false);
    const auth = useSelector(authSelector);
    const cartLoading = useSelector(cartLoadingSelector);
    const cartCount = useSelector(cartCountSelector)

    // =============================
    // Handlers ====================
    // =============================
    const openMenuHandle = () => setOpenMenu(true);

    const logoutHandle = () => {
        dispatch(cartReset());
        clearAllStorage();
        dispatch(logOut());
    };


    return (
        <header>
            <Navbar key={'xl'} fixed="top" expand={false} className={`${classes.nav} mb-3 ${classes.nav__sm} ${isRTL() && classes.rtl}`}>
                <Container fluid>
                    <div className={`${classes.cat__toggle}`} onClick={openMenuHandle}>
                        <HiViewGrid size={28} />
                    </div>

                    <Navbar.Brand href="/">
                        <img src={logo} alt='Malek Store' height={43} width={96} />
                    </Navbar.Brand>

                    <Navbar.Toggle className={classes.toggle} aria-controls={`offcanvasNavbar-expand-xl`} >
                        <HiMenu size={ICON_SIZE + 4} />
                    </Navbar.Toggle>

                    <Navbar.Offcanvas
                        id={`offcanvasNavbar-expand-xl`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-xl`}
                        placement="end"
                        className={`${classes.sm__offcanvas} ${isRTL() && classes.rtl__offcanvas}`}
                        style={{ direction: 'rtl' }}
                    >
                        <Offcanvas.Header closeButton className={classes.offcanvas__header}>
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-xl`}>
                                <img src={DarkLogo} alt={t("store_name")} height={50} width={123} />
                            </Offcanvas.Title>
                        </Offcanvas.Header>

                        <Offcanvas.Body className={classes.offcanvas__body}>
                            <Nav className="justify-content-end flex-grow-1 pe-3">
                                <NavLink className={`${classes.item__link}  nav-link`} to="/">
                                    <HiOutlineHome />
                                    {t("nav.home")}
                                </NavLink>
                                <NavLink className={`${classes.item__link} nav-link`} to="/products">
                                    <HiOutlineShoppingBag />
                                    {t("nav.products")}
                                </NavLink>
                                <NavLink className={`${classes.item__link} nav-link`} to="wishlist/">
                                    <HiOutlineHeart />
                                    {t("nav.wishlist")}
                                </NavLink>
                                <Link className={`${classes.item__link} nav-link`} to={'/products?search=true'}>
                                    <HiOutlineSearch />
                                    {t("nav.search")}
                                </Link>
                                <NavLink className={`${classes.item__link} nav-link`} to={'/cart'}>
                                    <HiOutlineShoppingCart />
                                    {t("nav.cart")}
                                </NavLink>
                                <NavLink className={`${classes.item__link} nav-link`} to="about-us/">
                                    <HiOutlineQuestionMarkCircle />
                                    {t("nav.about_us")}
                                </NavLink>
                                {auth.access ?
                                    <>
                                        <NavLink className={`${classes.item__link} nav-link`} to={'/profile'}>
                                            <HiOutlineUserCircle />
                                            {t("nav.profile")}
                                        </NavLink>
                                        <Nav.Link className={`${classes.item__link} nav-link`} onClick={logoutHandle}>
                                            <HiOutlineLogout />
                                            {t('nav.logout')}
                                        </Nav.Link>
                                    </>
                                    :
                                    <NavLink to={"/auth/login/"} className={`${classes.item__link} nav-link btn__primary mt-4`} >
                                        <HiOutlineLogin />
                                        {t("nav.signIn")}
                                    </NavLink>
                                }
                            </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
                <CatsMenu openMenu={openMenu} setOpenMenu={setOpenMenu} />
            </Navbar>
            <div className={classes.prop}>
            </div>
        </header >
    );
}

