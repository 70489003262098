import { jwtDecode } from "jwt-decode";


export function truncateWords(str: string, maxWords: number): string {
    // Split the string into words
    const words: string[] = str.split(" ");

    // If the number of words is less than or equal to the maximum allowed words,
    // return the original string
    if (words.length <= maxWords) {
        return str;
    }

    // Otherwise, join the first maxWords words and append an ellipsis
    return words.slice(0, maxWords).join(" ") + "...";
}


// ==============================================
/**
 * Represents the structure of a decoded JWT token.
 */
type TokenDataType = {
    token_type: string; // Type of token (e.g., "Bearer")
    exp: number; // Expiration time (Unix timestamp)
    iat: number; // Issued at time (Unix timestamp)
    jti: string; // JWT ID (unique identifier for the token)
    user_id: number; // User ID associated with the token
    username: string; // Username associated with the token,
    wishlist: number[]
};

/**
 * Extracts user credentials (username, access token, and refresh token) from JWT tokens.
 * @param access The access token
 * @param refresh The refresh token
 * @returns An object containing the username, access token, and refresh token
 */
export const getUserCredentials = (access: string, refresh: string) => {
    // Decode the access token
    const tokenData: TokenDataType = jwtDecode(access);

    // Extract username from decoded tokenData
    const username = tokenData.username;

    // Return an object containing username, access token, and refresh token
    return {
        username: username,
        access: access,
        refresh: refresh,
        wishlist: tokenData.wishlist
    };
};


export const capitalize = (title: string) => {
    return title.charAt(0).toUpperCase() + title.slice(1);
}


/**
 * Converts an object into URL parameters string, removing null values.
 * @param obj The object containing key-value pairs to be converted.
 * @returns A string containing URL parameters.
 */
export function getUrlParams(obj: Record<string, any>): string {
    const params = new URLSearchParams();

    for (const key in obj) {
        if (obj.hasOwnProperty(key) && obj[key] !== null) {
            params.append(key, obj[key]);
        }
    }

    return params.toString();
}



export function getRandomObjectsArray(subcategories: any[]): any[] {
    const length: number = subcategories.length; // Cache the length of the array

    // Generate two random indexes within the range of the array length
    const index1: number = Math.floor(Math.random() * length);
    let index2: number = Math.floor(Math.random() * length);

    // Ensure index2 is not the same as index1
    while (index2 === index1) {
        index2 = Math.floor(Math.random() * length);
    }

    // Return the two randomly selected array
    return [subcategories[index1], subcategories[index2]];
}