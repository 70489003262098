import React from 'react'
import classes from "./styles.module.css";


function NoConnection() {
    return (
        <div className={classes.wrapper}>

            <div className={classes.card}>
                No Connection
            </div>

        </div>
    )
}

export default NoConnection