
import classes from './styles.module.css';
import AnimatedBG from 'src/components/animatedBg/AnimatedBG';
import { FC, ReactNode } from 'react'
import { boolean } from 'yup';


interface HeaderProp {
    title?: string | null,
    children?: ReactNode | null
}

const Header: FC<HeaderProp> = ({ title, children }) => {
    return (
        <header className={classes.header}>
            <h4 className="">
                {Boolean(title) && title}
                {Boolean(children) && children}
            </h4>
            <AnimatedBG />
        </header>

    )
}

export default Header;