import { FC } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import classes from "./styles.module.css";
import { SPINNER_COLOR } from 'src/constants/colors';

interface ElementLoadingProp {
    styles?: { [key: string]: string }
    conStyles?: { [key: string]: string }
}

const ElementLoading: FC<ElementLoadingProp> = ({ styles, conStyles }) => {
    return (
        <div className={classes.spinner__con} style={{ ...conStyles }}>
            <Spinner className={classes.spinner} animation="border" role="status" style={{ color: SPINNER_COLOR, ...styles }}>
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    )
}

export default ElementLoading