import React, { Dispatch, FC, SetStateAction, memo } from 'react'
import classes from './styles.module.css';
import { FaMinus, FaPlus } from "react-icons/fa6";


interface QtyInput {
    qty: number,
    setQty: Dispatch<SetStateAction<number>>
}

const QtyInput: FC<QtyInput> = ({ qty, setQty }) => {


    const onChangeHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setQty(parseInt(event.target.value));
    }

    const onIncreaseHandle = (event: any) => {
        setQty((oldQty) => {
            if (oldQty < 10000) {
                return oldQty += 1;
            }
            return oldQty
        });
    }
    const onDecreaseHandle = (event: any) => {
        setQty((oldQty) => {
            if (oldQty > 1) {
                return oldQty -= 1;
            }
            return 1
        });
    }

    return (
        <div className={classes.qty__con}>
            <div className={classes.input__con}>
                <button onClick={onIncreaseHandle}>
                    <FaPlus size={16} />
                </button>
                <input min={1} max={10000} type='number' name="qty" value={qty} onChange={onChangeHandle} />
                <button onClick={onDecreaseHandle}>
                    <FaMinus size={16} />
                </button>
            </div>

        </div>
    )
}

export default memo(QtyInput);