import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import Nav from './nav/Nav';
import Footer from './footer/Footer';
import { LoadingPage } from 'src/components';
import { isRTL } from 'src/utils/global';
import styles from "./styles.module.css";


const MainLayout = () => {
    return (
        <div className={`${isRTL() ? ["rtl", styles.rtl].join(' ') : ''} ${styles.main__layout}`}>
            <Nav />
            <main>
                <Suspense fallback={<LoadingPage />}>
                    <Outlet />
                </Suspense>
            </main>
            <Footer />
        </div>
    );
}
export default MainLayout;
