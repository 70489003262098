import { FC } from 'react';
import classes from './styles.module.css';

interface EmptyElemntProps {
    img: string;
    title: string;
    msg?: string;
}

const EmptyElement: FC<EmptyElemntProps> = ({ img, title, msg }) => {
    return (
        <div className={classes.wrapper}>
            <div className={classes.img__con}>
                <img src={img} alt={title} />
            </div>
            <h4>
                {title}
            </h4>
            <p>
                {msg}
            </p>
        </div>
    )
}

export default EmptyElement