import { useLocation } from "react-router-dom";

interface LocationState {
    // Define the shape of your location state if you have a specific structure
    [key: string]: any;
}

const useCustomLocation = () => {
    const location = useLocation();
    return {
        path: location.pathname,
        query: new URLSearchParams(location.search),
        state: location.state as LocationState
    };
};

export default useCustomLocation;
