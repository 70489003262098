import i18n from 'i18next';
import { scroller } from 'react-scroll';
import { jwtDecode } from 'jwt-decode';

/**
 * Checks if the current language is a right-to-left (RTL) language.
 * 
 * This function uses the current language from the i18next instance and compares it 
 * against a predefined list of RTL languages. If the current language is in the list,
 * the function returns true, indicating that the language is RTL. Otherwise, it returns false.
 * 
 * @returns {boolean} True if the current language is RTL, otherwise false.
 */
export const isRTL = () => {
    const RTL_LANGUAGES = ['ar', 'he', 'fa', 'ur'];
    const currentLanguage = i18n.language; // get current language from i18n
    return RTL_LANGUAGES.includes(currentLanguage);
};



export const scrollToElement = (name: string) => {
    scroller.scrollTo(name, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
        offset: -window.innerHeight / 2 + 180,
    });
}



/**
 * Truncates a string to a specified maximum number of words and/or characters.
 * 
 * @param str - The string to be truncated.
 * @param maxWords - The maximum number of words allowed in the truncated string.
 * @param maxChars - The maximum number of characters allowed in the truncated string.
 * @returns The truncated string with an ellipsis appended if truncation occurs, otherwise the original string.
 */
export function truncateText(str: string, maxWords: number, maxChars: number): string {
    // Split the string into words
    const words: string[] = str.split(" ");

    // Initialize variables to keep track of the total character count and the truncated string
    let charCount = 0;
    let truncatedString = "";

    // Iterate through each word in the string
    for (let i = 0; i < words.length; i++) {
        // Check if adding the next word would exceed the character limit or word limit
        if (charCount + words[i].length + (i > 0 ? 1 : 0) > maxChars || i >= maxWords) {
            // If either limit is reached, append an ellipsis and return the truncated string
            return truncatedString.trim() + "...";
        }

        // Add the current word to the truncated string
        // Add a space before the word if it's not the first word
        truncatedString += (i > 0 ? " " : "") + words[i];

        // Update the character count (including space if it's not the first word)
        charCount += words[i].length + (i > 0 ? 1 : 0);
    }

    // If the loop completes without hitting any limits, return the original or truncated string
    return truncatedString;
}


/**
 * Truncates a string to a specified maximum number of words.
 * 
 * @param str - The string to be truncated.
 * @param maxWords - The maximum number of words allowed in the truncated string.
 * @returns The truncated string with an ellipsis appended if truncation occurs, otherwise the original string.
 */
export function truncateWords(str: string, maxWords: number): string {
    // Split the string into words
    const words: string[] = str.split(" ");

    // If the number of words is less than or equal to the maximum allowed words,
    // return the original string
    if (words.length <= maxWords) {
        return str;
    }

    // Otherwise, join the first maxWords words and append an ellipsis
    return words.slice(0, maxWords).join(" ") + "...";
}


export const copyToClipBoard = async (copyMe: string) => {
    try {
        await navigator.clipboard.writeText(copyMe);
        return true;
    } catch (err) {
        return false
    }
};

export const getTokenData = (token: string) => {
    let data = { username: '', email: '' }
    if (token) {
        try {
            // Decode the token
            data = jwtDecode(token);
        } catch (error) {
            console.error('Invalid token:');
            return data;
        }
    }
    return data;
};


export const capitalize = (title: string) => {
    return title.charAt(0).toUpperCase() + title.slice(1);
}
