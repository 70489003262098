// export const BASE_URL: string = "http://127.0.0.1:8000/api";

export const BASE_URL: string = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8000/api' : 'https://malek-store.ly/api';

export const RECAPTCHA_PUBLIC_KEY: string = process.env.NODE_ENV === 'development' ? '6LeAZ6IpAAAAAFt1Ln6qQwWkbVZhYjh9AeY5gXVu' : '6Lcr9f0pAAAAAK1RxHWtyA3cyEnyMiPTubR7l1jw';

//  Local
// export const RECAPTCHA_PUBLIC_KEY: string = '6LeAZ6IpAAAAAFt1Ln6qQwWkbVZhYjh9AeY5gXVu';

// Production
// export const RECAPTCHA_PUBLIC_KEY: string = '6Lcr9f0pAAAAAK1RxHWtyA3cyEnyMiPTubR7l1jw';

export const DEFAULT_EMAIL: string = "info@malek-store.ly"