import Pagination from 'react-bootstrap/Pagination';
import { memo, FC } from "react";

import classes from './styles.module.css';
import { useTranslation } from 'react-i18next';

interface ProductsPaginationProp {
    pagesNumber: number,
    currentPage: number,
    setPage: any
}


const ProductsPagination: FC<ProductsPaginationProp> = ({ pagesNumber,
    currentPage, setPage }) => {
    const items = [];
    const { t } = useTranslation(["products"]);

    // Loop through page numbers and generate Pagination.Item components
    for (let i = 1; i <= pagesNumber; i++) {
        if (i > currentPage - 4 && i < currentPage + 4) {
            items.push(
                <Pagination.Item key={`page_${i}`} active={i === currentPage} onClick={() => pageClickHandle(i)}>
                    {i}
                </Pagination.Item>
            );
        }

    }


    const pageClickHandle = (page: number) => {
        setPage(page);
    }

    return (
        <Pagination className={classes.pagination}>
            {currentPage > 1 && <>
                <Pagination.First title={t("pagination.first_page")} className={classes.pagination__first} disabled={currentPage === 1} onClick={() => pageClickHandle(1)} />
                <Pagination.Prev title={t("pagination.previous")} className={classes.pagination__prev} disabled={currentPage === 1} onClick={() => pageClickHandle(currentPage - 1)} />
            </>}
            {items}
            {pagesNumber !== currentPage && <>
                <Pagination.Next title={t("pagination.next_page")} disabled={currentPage === pagesNumber} onClick={() => pageClickHandle(currentPage + 1)} />
                <Pagination.Last title={t("pagination.last_page")} disabled={currentPage === pagesNumber} onClick={() => pageClickHandle(pagesNumber)} />
            </>}

        </Pagination>
    );
};



export default memo(ProductsPagination);