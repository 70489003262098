import { useTranslation } from "react-i18next";

import classes from "./styles.module.css";
import { isRTL } from "src/utils/global";
import DefaultProductImg from 'src/assets/images/default_product_img.png';


const DefaultProduct = () => {
    const { t } = useTranslation(['products']);

    return (
        <div className={`${classes.product} ${isRTL() && classes.rtl}`}>
            <div className={classes.card}>
                <div className={classes.img__con}>
                    <img src={DefaultProductImg} alt={t("default_product.alt")} loading="lazy" />
                </div>
                <div className={classes.content}>
                    <h4 className={classes.title}>
                        <a>
                            {t("default_product.name")}
                        </a>
                    </h4>

                    <p className={classes.default__text}>
                        {t("default_product.content")}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default DefaultProduct;
