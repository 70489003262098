import { useTranslation } from "react-i18next";
import Title from "../title/Title";
import classes from "./styles.module.css";
import ProductsSlider from "../newset/productsSlider/ProductsSlider";
import { FC, memo } from "react";
import { ProductType } from "src/pages/products/product/types";

interface OffersProp {
    data: ProductType[]
}

const Offers: FC<OffersProp> = ({ data }) => {
    const { t } = useTranslation(['home']);

    return (
        <section className={classes.offers}>
            <div className="container">
                <Title title={t('offers.title')} />
                <ProductsSlider data={data} />
            </div>
        </section>
    )
}

export default Offers;


