// hooks/useError.ts
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';



const useError = (excludedStatus: (number | string)[] = []) => {
    const { t } = useTranslation(['common']);
    const showError = useCallback((error: any, customKeys?: { [key: string]: string }) => {
        const errorKeys: { [key: string]: string } = {
            "FETCH_ERROR": "network",
            "400": "bad_request",
            "401": "unauthorized",
            "403": "permissions",
            "404": "not_found",
            "500": "server",
            ...customKeys
        };
        if ('status' in error) {
            if (!excludedStatus.includes(error.status)) {
                toast.error(t(`errors.${errorKeys[error.status] || "server"}`));
            }
        }
    }, []);

    return { showError };
};

export default useError;
