import { FC } from 'react';
import { useTranslation } from "react-i18next";

import classes from "./styles.module.css";
import Title from "../title/Title";
import Products from "./productsSlider/ProductsSlider";
import { ProductType } from "src/pages/products/product/types"


interface NewsetProp {
    data: ProductType[]
}

const Newset: FC<NewsetProp> = ({ data }) => {
    const { t } = useTranslation(['home']);


    return (
        <section className={classes.newset}>
            <div className="container">
                <Title title={t("newset.title")} />
                <Products data={data} />
            </div>
        </section>
    )
}

export default Newset;