import { FC, useState, useEffect } from "react";
import { FaRegHeart, FaHeart } from "react-icons/fa6";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";


import classes from "./styles.module.css";
import { truncateWords } from "src/utils";
import { ProductType } from "./types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useCart from "src/hooks/useCartActions";
import { QtyInput } from "src/components";
import { useLocaitonQuery, useWishlist } from "src/hooks";
import { authSelector } from "src/store/reducers/auth";
import { isRTL } from "src/utils/global";


interface ProductProp {
    product: ProductType
}

const Product: FC<ProductProp> = ({ product }) => {
    const navigate = useNavigate();
    const { path } = useLocaitonQuery();
    const { t } = useTranslation(['products']);

    const auth = useSelector(authSelector);
    const [inWishlist, setInWishlist] = useState(product.in_wishlist);
    const { wishlistAdding, wishlistRemoving } = useWishlist();

    const { checkHandle, addHandle,
        removeHandle, getItemQty, updateQtyHandle } = useCart();

    const [qty, setQty] = useState(getItemQty(product.slug));


    const productDetailHandle = () => {
        navigate(`/products/${product.slug}/`)
    }

    const wishlistToggleHandle = () => {
        if (auth.access) {
            setInWishlist((oldState) => !oldState);

            if (!inWishlist) {
                wishlistAdding(product.id);
            } else {
                wishlistRemoving(product.id)
            }
        } else {
            navigate(`/auth/login/?next=${path}`);
        }
    }

    const addToCartHandle = () => addHandle({
        slug: product.slug,
        name: product.name,
        img: product.thumbnail,
        qty: 1,
        price: product.price,
        disc_price: product.discounted_price
    })

    useEffect(() => {
        if (getItemQty(product.slug) !== qty) {
            updateQtyHandle(product.slug, qty)
        }
    }, [qty])

    return (
        <div className={`${classes.product} ${isRTL() && classes.rtl}`}>
            <div className={classes.card}>
                <div className={classes.img__con}>
                    <img src={product.thumbnail} alt={product.name} loading="lazy" width={308} height={231} />

                    <div className={classes.img__header}>
                        <div className={`${classes.wishlist} ${inWishlist ? classes.item__active : ''}`} onClick={wishlistToggleHandle}>
                            {!inWishlist ? <FaRegHeart size={20} /> : <FaHeart size={20} />}
                        </div>
                    </div>
                    <div className={classes.overlay} onClick={productDetailHandle}>

                    </div>
                </div>
                <div className={classes.content}>
                    <h4 className={classes.title}>
                        <Link to={`/products/${product.slug}/`}>
                            {truncateWords(product.name, 6)}
                        </Link>
                    </h4>

                    {
                        product.in_stock ? <div className={classes.in_stock}>
                            {t("product.in_stock")}
                        </div> :
                            product.available ?
                                <div className={classes.available}>
                                    {t("product.available")}
                                </div>
                                :
                                <div className={classes.out_of_stock}>
                                    {t("product.out_of_stock")}
                                </div>
                    }
                    <p className={classes.price}>
                        {product.discounted_price && <span className={classes.discounted_price}>
                            {product.discounted_price} {t('common:lyd')}
                        </span>}
                        <span className={`${classes.original} ${product.discounted_price ? classes.org_discounted_price : ''}`}>
                            {product.price} {t('common:lyd')}
                        </span>
                    </p>
                    <div>
                        {!checkHandle(product.slug) ?
                            <button onClick={addToCartHandle} className={`btn__primary ${classes.cart_add_btn}`}>
                                {t('common:add_to_cart')}
                            </button> :
                            <div className={classes.remove__item}>
                                <button onClick={() => removeHandle(product.slug)} id="variant_3" className={`btn__primary ${classes.cart_remove_btn}`}>
                                    {t('cart.remove')}
                                </button>
                                <div className={classes.in_Cart_qty}>
                                    <QtyInput qty={qty} setQty={setQty} />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Product;
